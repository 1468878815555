import CanvasMapper from '../CanvasMapper';
import moment from '../../../moment';

function CryptoNewsMapper() {
  CanvasMapper.apply(this, arguments);
  this.name = 'CryptoNewsMapper';
}

CryptoNewsMapper.prototype = Object.create(CanvasMapper.prototype);
CryptoNewsMapper.prototype.constructor = CryptoNewsMapper;

CryptoNewsMapper.prototype.mapSettings = function(t, manifest, feed) {
  CanvasMapper.prototype.mapSettings.apply(this, arguments);
  return this
    .mapImageFromManifest(t, 'source', manifest);
};

CryptoNewsMapper.prototype.mapItem = function(_, item, manifest) {
  var t = CanvasMapper.prototype.mapItem.apply(this, arguments);
  if (item) {
    moment.locale('en');
    t.id = item['id'];
    t.type = 'news';
    t.date = moment(item['published_at']).format('HH:mm - MMM D');
    t.message.text = item['title'];
    t.source.text = item['source'] && item['source']['title'];
    if (item['votes']) {
      if (Object.keys(item['votes']).filter(v => item['votes'][v] >= 2).length >= 2) {
        t.sentiment = 'neutral';
      } else if (item['votes']['positive'] && item['votes']['positive'] >= item['votes']['negative']) {
        t.sentiment = 'positive';
      } else if (item['votes']['negative'] && item['votes']['negative'] > item['votes']['positive']) {
        t.sentiment = 'negative';
      } else {
        t.sentiment = '';
      }
    }
    if (item['currencies']) {
      t.tickers = item['currencies'].map(c => c['code']);
    }
  }
  return t;
};

export default CryptoNewsMapper;
