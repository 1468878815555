import CanvasCollection from '../CanvasCollection';

function CryptoNewsCollection() {
  CanvasCollection.apply(this, arguments);
  this.name = 'CryptoNewsCollection';
}

CryptoNewsCollection.prototype = Object.create(CanvasCollection.prototype);
CryptoNewsCollection.prototype.constructor = CryptoNewsCollection;

CryptoNewsCollection.prototype.checkItem = function(item) {
  if (
    item['title'] &&
    item['source'] &&
    item['published_at']
  ) {
    item['type'] = 'news';
    return true;
  }
  return false;
};

export default CryptoNewsCollection;
