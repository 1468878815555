import CanvasCollection from '../CanvasCollection';

function CryptoVideosCollection() {
  CanvasCollection.apply(this, arguments);
  this.name = 'CryptoVideosCollection';
}

CryptoVideosCollection.prototype = Object.create(CanvasCollection.prototype);
CryptoVideosCollection.prototype.constructor = CryptoVideosCollection;

CryptoVideosCollection.prototype.checkItem = function(item) {
  if (
    !item['hidden'] &&
    item['url'] &&
    item['width'] &&
    item['height'] &&
    item['duration'] &&
    item['date']
  ) {
    item['type'] = 'video';
    return true;
  }
  return false;
};

CryptoVideosCollection.prototype.checkCategory = function (item, categories) {
  if (!categories) return true;
  const condition =
    categories.indexOf('landscape') > -1 && item.width > item.height ||
    categories.indexOf('portrait') > -1 && item.width < item.height;
  return condition;
}

CryptoVideosCollection.prototype.getNext = function(categories) {
  if (typeof this.count === 'undefined') {
    this.count = 0;
  } else {
    this.count = this.count + 1;
  }

  let min;
  if (this.items) {
    for (let i = 0; i < this.items.length; i++) {
      if (this.checkCategory(this.items[i], categories) && (!min || this.items[i].order < min.order)) {
        min = this.items[i];
      }
    }
    if (!min && this.items.length === 1) {
      min = this.items[0];
    }
  } else {
    console.warn('collection items undefined');
    return null;
  }

  if (min) {
    min.order = ++this.order;
    return min;
  } else {
    console.warn('empty collection', this);
    return null;
  }
};

export default CryptoVideosCollection;
