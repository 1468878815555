import CanvasMapper from '../CanvasMapper';
import moment from '../../../moment';

function CanvasSponsoredMapper() {
  CanvasMapper.apply(this, arguments);
  this.name = 'CanvasSponsoredMapper';
}

CanvasSponsoredMapper.prototype = Object.create(CanvasMapper.prototype);
CanvasSponsoredMapper.prototype.constructor = CanvasSponsoredMapper;

CanvasSponsoredMapper.prototype.mapItem = function(_, item, manifest) {
  var t = CanvasMapper.prototype.mapItem.apply(this, arguments);
  if (item) {
    moment.locale('en');
    t.id = item['id'];
    t.type = 'sponsored';
    t.date = moment(item['date']).format('HH:mm - MMM D');
    t.image = {
      url: item['imageUrl'],
      width: item['width'],
      height: item['height'],
    };
    t.message.text = item['text'];
  }
  return t;
};

export default CanvasSponsoredMapper;
