import SeenspirePresentation from '../../SeenspirePresentation';
import CryptoCoinsMapper from './CryptoCoinsMapper';
import CryptoNewsMapper from './CryptoNewsMapper';
import CryptoCoinsCollection from './CryptoCoinsCollection';
import CryptoNewsCollection from './CryptoNewsCollection';
import Seenspire from '../../Seenspire';
import { Lightning } from '@lightningjs/sdk';

import {
  utils,
  CanvasCoinBox,
  CanvasNewsBox,
  CanvasGreedIndexBox,
  CanvasMatrixBox,
} from 'private/seenspire';

class Matrix extends Seenspire {
  constructor(stage, properties) {
    super(stage, properties);
    this.presentation = properties.presentation;
  }
  process(callback, scope) {
    this.c.Slide.process(callback, scope);
  }
  play() {
    this.c.Slide.play();
  }
  _construct() {
    Seenspire.prototype._construct.apply(this, arguments);
    this.c.Slide = new CanvasMatrixBox(
      'Slide',
      {
        config: this.options.template,
        portrait: false,
        mode: 'main',
        presentation: this.presentation,
      },
      null,
      null,
      Lightning
    );
  }
  _build() {
    Seenspire.prototype._build.apply(this, arguments);
    this.c.Slide.setTag(this.tag('Slide'));
    this.c.Slide.initMatrix();
  }
}

export default class CryptoCanvasPresentation extends SeenspirePresentation {
  constructor(stage, properties) {
    super(stage, properties);
    this.updateMode();
  }

  createMatrix() {
    this.matrix = new Matrix(this.stage, {
      clipping: true,
      rect: true,
      color: '0x00000000',
      x: 0,
      y: 0,
      w: window.innerWidth,
      h: window.innerHeight,
      type: Matrix,
      visible: true,
      presentation: this,
      options: {
        template: this.template,
      },
    });
    this.childList.add(this.matrix);
  }

  getMapperClass() {
    return CryptoCanvasMapper;
  }

  createCollection() {
    this.collectionCoins = new CryptoCoinsCollection('coins-collection', {
      data: this.app.feedItems.coins,
      shuffle: this.template.random
    });
    this.collectionNews = new CryptoNewsCollection('news-collection', {
      data: this.app.feedItems.news,
      shuffle: this.template.random
    });
    return this;
  }

  createMapper() {
    this.mapperCoins = new CryptoCoinsMapper('coins-mapper', {
      template: this.template,
      manifest: this.manifest,
    });
    this.mapperNews = new CryptoNewsMapper('news-mapper', {
      template: this.template,
      manifest: this.manifest,
    });
    return this;
  }

  mapSettings() {
    this.mapperCoins.mapSettings(this.template, this.manifest, this.app.feedSettings);
    this.mapperNews.mapSettings(this.template, this.manifest, this.app.feedSettings);
    return this;
  }

  mapItem(item) {
    if (item.type === 'coin') this.item = this.mapperCoins.mapItem(this.template, item, this.manifest);
    if (item.type === 'news') this.item = this.mapperNews.mapItem(this.template, item, this.manifest);
    return this;
  }

  start() {
    this.item = utils.clone(this.template);

    this.checkFonts(
      function() {
        this.createMatrix();
        if (this.matrix) {
          this.matrix.process(this.onReady, this);
        }
      },
      null,
      this
    );

    return this;
  }

  loop(start) {
    this.updateMode();

    if (start) {
      this.checkFonts(this.prepareNextSlide, null, this);
    } else {
      this.getNextItem(function(item) {
        this.mapItem(item).checkFonts(this.prepareNextSlide, null, this);
      }, this);
    }
    return this;
  }

  getNextItem(callback, scope, type, categories) {
    if (this.collectionCoins && this.collectionNews) {

      if (!type) {
        type = Math.random() > 0.2 ? 'coin' : 'news';
      }
      let item;
      if (type === 'coin') item = this.collectionCoins.getNext();
      if (type === 'news') item = this.collectionNews.getNext();

      if (!item) {
        return this;
      }
      this.cleanUpItem();
      this.preloadItem(
        item,
        function() {
          if (callback) {
            callback.call(scope, item);
          }
        },
        function() {
          this.cleanUpItem(item);
          this.getNextItem(callback, scope, type, categories);
        },
        this
      );
    }
    return this;
  }

  preloadItem(item, success, failed, scope) {
    if (item.type === 'coin') {
      if (!item.logo) {
        item.logo = {
          url: item.iconUrl,
          required: true
        }
      }
      if (item.logo.url) {
        item.logo.required = true;
        this.app.imageLoader.preload(
          item.logo,
          function() {
            if (item.logo.img) {
              this.preloadManifest(success, failed, scope);
            } else if (failed) {
              failed.call(scope);
            }
          },
          this
        );
      } else if (failed) {
        failed.call(scope);
      }
    } else {
      this.preloadManifest(success, failed, scope);
    }
    return this;
  }

  cleanUpItem(item) {
    if (item?.logo?.img) {
      delete item.logo.img;
      item.logo.img = null;
      item.logo.loaded = false;
    }
    return this;
  }

  play() {
    if (this.matrix) {
      this.onFrame(
        function() {
          this.matrix.play();
        },
        this,
        this.app.bypass
      );
    }
    return this;
  }

  getItemClass(type) {
    switch (type) {
      case 'coin':
        return CanvasCoinBox;
      case 'news':
        return CanvasNewsBox;
      case 'greed-index':
        return CanvasGreedIndexBox;
      default:
        return CanvasCoinBox;
    }
  }

  createGreedIndex = function (width, height) {
    const item = this.getGreedIndexInfo();
    return this.createItem(item, width, height);
  };

  createFeed(item, width, height) {
    if (item) {
      if (item.type === 'coin') item = this.mapperCoins.mapItem(this.template, item, this.manifest);
      if (item.type === 'news') item = this.mapperNews.mapItem(this.template, item, this.manifest);
    }
    return this.createItem(item, width, height);
  }

  createItem(item, width, height) {
    if (item) {
      return new (this.getItemClass(item.type))(
        undefined,
        {
          width: width,
          height: height,
          portrait: false,
          config: item,
        },
        undefined,
        undefined,
        Lightning
      )._template();
    }
    return {};
  }

  getGreedIndexInfo() {
    return {
      greed: this.template.greed,
      donut: this.template.donut,
      holder: this.template.holder,
      arrow: this.template.arrow,
      fonts: this.template.fonts,
      type: 'greed-index'
    }
  }
}
