import CanvasCollection from '../CanvasCollection';

function CryptoNewsCollection() {
  CanvasCollection.apply(this, arguments);
  this.name = 'CryptoNewsCollection';
}

CryptoNewsCollection.prototype = Object.create(CanvasCollection.prototype);
CryptoNewsCollection.prototype.constructor = CryptoNewsCollection;

CryptoNewsCollection.prototype.checkItem = function(item) {
  if (
    item['image_url'] &&
    item['title'] &&
    item['source_name'] &&
    item['date']
  ) {
    item['type'] = 'news';
    return true;
  }
  return false;
};

CryptoNewsCollection.prototype.addProxy = function(item) {
  if (document.domain === 'localhost') {
    if (item['image_url']) {
      item['image_url'] = 'http://localhost:3000/proxy?url=' + item['image_url'];
    }
  }
  return this;
};

export default CryptoNewsCollection;
