import moment from '../moment';
import { Seenspire, utils } from 'private/seenspire';

function SeenspireCollection() {
  Seenspire.apply(this, arguments);
  this.name = 'SeenspireCollection';
  this._init();
}

SeenspireCollection.prototype = Object.create(Seenspire.prototype);
SeenspireCollection.prototype.constructor = SeenspireCollection;

SeenspireCollection.prototype._init = function() {
  let i, data;

  if (this.options.data) {
    this.items = [];

    for (i = 0; i < this.options.data.length; i++) {
      data = this.options.data[i];

      this.setType(data);
      this.addProxy(data);

      if (this.checkItem(data)) {
        this.items.push(data);
      }
    }

    this._addOrder();
  }

  return this;
};

SeenspireCollection.prototype.addProxy = function() {
  return this;
};

SeenspireCollection.prototype.setType = function(item) {
  if (!item.type) {
    item.type = 'text';
  }
  return this;
};

SeenspireCollection.prototype.checkItem = function() {
  return this;
};

SeenspireCollection.prototype.updateCollection = function(options) {
  this.options = utils.clone(options);
  this._init();
  return this;
};

SeenspireCollection.prototype.insertItem = function(item) {
  const data = item;
  if (data) {
    this.setType(data);
    if (!this.checkItem(data)) return this;
    if (this.items && !this.updateItem(data)) {
      if (this.options.shuffle) {
        this.items.unshift(data);
        this.shuffle(this.items);
      } else {
        this.items.push(data);
        this.items = this.items.sort(function (a, b) {
          return b.timestamp - a.timestamp;
        });
      }
      this.shiftOrder();
    }
  }
  return this;
};

SeenspireCollection.prototype.updateItem = function(item) {
  var i, o;
  if (item && this.items) {
    for (i = 0; i < this.items.length; i++) {
      if (item.id === this.items[i].id) {
        o = this.items[i].order;
        this.items[i] = item;
        this.items[i].order = o;
        return true;
      }
    }
  }
  return false;
};

SeenspireCollection.prototype.shiftOrder = function() {
  if (this.items && this.order) {
    for (let i = 0; i < this.items.length; i++) {
      this.items[i].order = this.items[i].order || this.order;
    }
  }
  return this;
};

SeenspireCollection.prototype._addOrder = function() {
  if (!this.order) {
    this.order = 0;
  }

  if (this.options.shuffle) {
    this.shuffle(this.items);
  } else {
    this.items = this.items.sort(function(a, b) {
      return b.timestamp - a.timestamp;
    });
  }

  for (let i = 0; i < this.items.length; i++) {
    this.items[i].order = this.order;
    this.order++;
  }

  return this;
};

SeenspireCollection.prototype.shuffle = function(items) {
  var j, x, i;
  for (i = items.length; i; i--) {
    j = Math.floor(Math.random() * i);
    x = items[i - 1];
    items[i - 1] = items[j];
    items[j] = x;
  }
  return this;
};

SeenspireCollection.prototype.setRandom = function(random) {
  if (this.options.shuffle !== random) {
    this.options.shuffle = random;
    this._addOrder();
  }
  return this;
};

SeenspireCollection.prototype.getNext = function() {
  if (typeof this.count === 'undefined') {
    this.count = 0;
  } else {
    this.count = this.count + 1;
  }

  let min;
  if (this.items) {
    for (let i = 0; i < this.items.length; i++) {
      if (!min || this.items[i].order < min.order) {
        min = this.items[i];
      }
    }
    if (!min && this.items.length === 1) {
      min = this.items[0];
    }
  } else {
    console.warn('collection items undefined');
    return null;
  }

  if (min) {
    min.order = ++this.order;
    return min;
  } else {
    console.warn('empty collection', this);
    return null;
  }
};

export default SeenspireCollection;
