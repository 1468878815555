import SeenspireMapper from '../SeenspireMapper';

function CanvasMapper() {
  SeenspireMapper.apply(this, arguments);
  this.name = 'CanvasMapper';
}

CanvasMapper.prototype = Object.create(SeenspireMapper.prototype);
CanvasMapper.prototype.constructor = CanvasMapper;

export default CanvasMapper;
