import CanvasMapper from '../CanvasMapper';
import moment from '../../../moment';

function CryptoNewsMapper() {
  CanvasMapper.apply(this, arguments);
  this.name = 'CryptoNewsMapper';
}

CryptoNewsMapper.prototype = Object.create(CanvasMapper.prototype);
CryptoNewsMapper.prototype.constructor = CryptoNewsMapper;

CryptoNewsMapper.prototype.mapSettings = function(t, manifest, feed) {
  CanvasMapper.prototype.mapSettings.apply(this, arguments);
  return this
    .mapImageFromManifest(t, 'source', manifest);
};

CryptoNewsMapper.prototype.mapItem = function(_, item, manifest) {
  var t = CanvasMapper.prototype.mapItem.apply(this, arguments);
  if (item) {
    moment.locale('en');
    t.id = item['id'];
    t.type = 'news';
    t.date = moment(item['date']).format('HH:mm - MMM D');
    t.message.text = item['title'];
  }
  return t;
};

export default CryptoNewsMapper;
