import SeenspireCollection from '../SeenspireCollection';

function CanvasCollection() {
  SeenspireCollection.apply(this, arguments);
  this.name = 'CanvasCollection';
}

CanvasCollection.prototype = Object.create(SeenspireCollection.prototype);
CanvasCollection.prototype.constructor = CanvasCollection;

export default CanvasCollection;
