import moment from 'moment'

export function stepped(a, steps) {
  if (a && a.length) {
    a.sort((a, b) => a.time - b.time)
    var step = a.length / steps
    return new Array(steps).fill(0).map((v, i) => {
      return a[Math.floor((i+1)*step)-1]
    }).filter(h => !!h)
  }
}

export function filterByDate(opt, array) {
  let start = 0
  if (!array.length) return array
  const recent = array.sort((a, b) => a.time - b.time).slice(-1)[0].time
  switch (opt) {
    case 'history_1_day':
      start = moment(recent).subtract(1, 'day').valueOf()
      break
    case 'history_1_week':
      start = moment(recent).subtract(1, 'week').valueOf()
      break
    case 'history_2_weeks':
      start = moment(recent).subtract(2, 'weeks').valueOf()
      break
    case 'history_1_month':
      start = moment(recent).subtract(1, 'month').valueOf()
      break
    case 'history_1_year':
      start = moment(recent).subtract(1, 'year').valueOf()
      break
  }
  return array.filter(record => record.time > start)
}

export function preprocessCoin(asset) {
  if (asset) {
    delete asset.history;
    delete asset.history_24_hours_5mins;
    delete asset.history_3_days;
    asset.history_1_day = stepped(filterByDate('history_1_day', asset.history_1_day), 24);
    asset.history_1_month = stepped(filterByDate('history_1_month', asset.history_1_month), 24);
    asset.history_1_week = stepped(filterByDate('history_1_week', asset.history_1_week), 24);
    asset.history_2_weeks = stepped(filterByDate('history_2_weeks', asset.history_2_weeks), 24);
    asset.history_1_year = stepped(filterByDate('history_1_year', asset.history_1_year), 24);
  }
}

export function compressHistory(data) {
  if (data && data[0] && data[0].profile && data[0].profile.profile_assets) {
    for(let asset of data[0].profile.profile_assets) {
      if (asset.assets) {
        preprocessCoin(asset.assets)
      }
    }
  }
  if (data && data[0] && data[0].history) {
    for(let asset of data) {
      if (asset) {
        preprocessCoin(asset)
      }
    }
  }
}
