import { Lightning, Router } from '@lightningjs/sdk'
import { getCode } from './utils'
import { showLoader } from './loader'
import realtime from './Realtime'

export default class Splash extends Lightning.Component {
  constructor(stage, props) {
    super(stage, props)
    // console.log('SPLASH', props, document.location.hash)
    if (document.location.hash === '') {
      const [existing, generated] = getCode()
      console.log(`SPLASH existing: ${existing}`)
      if (existing) {
        showLoader()
        realtime.fetchScreen(existing, (data) => {
          console.log('FETCHED', data)
          if (data && data.length && data[0] && data[0]['profile_id']) {
            Router.navigate(`crypto/${existing}`, { profileId: data[0]['profile_id'] })
          } else {
            Router.navigate('qr', {qr: existing})
          }
        }, true)
      }
      if (generated) {
        Router.navigate('qr', {qr: generated})
      }
    }
  }

  set params(args) {
    // console.log('SPLASH ARGS', args, document.location.hash)
  }

  static _template() {
    return {
      w: window.innerWidth,
      h: window.innerHeight,
      color: '0x00000000',
      rect: true,
      clipping: true,
    };
  }
}
