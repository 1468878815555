import { Lightning } from '@lightningjs/sdk';

import SeenspireMapper from './SeenspireMapper';
import SeenspireCollection from './SeenspireCollection';

export default class SeenspirePresentation extends Lightning.Component {
  constructor(stage, properties) {
    super(stage, properties);
    if (properties.config) {
      this.template = properties.config.template;
      this.manifest = properties.config.manifest;
      this.app = properties.config.app;
      this.createMapper();
      this.mapSettings();
      this.createCollection();
    }
  }

  createMapper() {
    this.mapper = new (this.getMapperClass())('mapper', this.getMapperConfig());
    return this;
  }

  getMapperClass() {
    return SeenspireMapper;
  }

  getMapperConfig() {
    return {
      template: this.template,
      manifest: this.manifest,
    };
  }

  mapSettings() {
    this.mapper.mapSettings(this.template, this.manifest, this.app.feedSettings);
    return this;
  }

  mapItem(item) {
    this.item = this.mapper.mapItem(this.template, item, this.manifest);
    return this;
  }

  createCollection() {
    this.collection = new (this.getCollectionClass())('collection', this.getCollectionConfig());
    return this;
  }

  getCollectionClass() {
    return SeenspireCollection;
  }

  getCollectionConfig() {
    return {
      data: this.app.feedItems,
      shuffle: this.template.random,
    };
  }

  updateMode() {
    this.mode = 'main';
    return this;
  }

  getNextItem(callback, scope, first) {
    let item;
    if (this.collection) {
      item = this.collection.getNext();
      if (!item) {
        return this.triggerEmptyCollection();
      }
      this.cleanUpItem();
      this.preloadItem(
        item,
        function() {
          if (callback) {
            callback.call(scope, item);
          }
        },
        function() {
          this.cleanUpItem(item);
          this.collection.banItem(item);
          this.getNextItem(callback, scope, first);
        },
        this,
        first
      );
    }
    return this;
  }

  triggerEmptyCollection() {
    return this;
  }

  preloadItem(item, success, failed, scope) {
    return this.preloadManifest(success, failed, scope);
  }

  preloadManifest(success, failed, scope, bypass) {
    if (bypass) {
      this.app.loadManifestImages();
    } else {
      this.app.loadManifestImages(success, failed, scope);
    }
    return this;
  }

  cleanUpItem() {
    return this;
  }

  checkFonts(callback, params, scope) {
    this.app.addFont(this.item.font, this.item.fontPath, function() {
      callback.call(scope, params);
    });
    return this;
  }

  ready(callback, scope) {
    if (callback) {
      if (typeof callback === 'function') {
        if (this.isReady) {
          callback.call(scope || this);
        } else {
          if (!this.eventReady) {
            this.eventReady = {};
          }
          this.eventReady.callback = callback;
          this.eventReady.scope = scope;
        }
      }
    } else {
      this.isReady = true;
      if (this.eventReady && this.eventReady.callback) {
        this.eventReady.callback.call(this.eventReady.scope || this);
      }
    }
    return this;
  }

  onReady() {
    if (this.isReady) {
      this.play();
    } else {
      this.ready();
    }
    return this;
  }

  onFrame(callback, scope, bypass) {
    if (callback) {
      if (bypass) {
        callback.call(scope);
        return this;
      }
      window.requestAnimationFrame(function() {
        callback.call(scope);
      });
    }
    return this;
  }

  play() {
    return this;
  }

  getOutDelay() {
    return 0;
  }

  static _template() {
    return {
      w: window.innerWidth,
      h: window.innerHeight,
      color: '0x00000000',
      rect: true,
      clipping: true,
    };
  }
}
