import SeenspireMapper from '../SeenspireMapper';

function CanvasMapper() {
  SeenspireMapper.apply(this, arguments);
  this.name = 'CanvasMapper';
}

CanvasMapper.prototype = Object.create(SeenspireMapper.prototype);
CanvasMapper.prototype.constructor = CanvasMapper;

CanvasMapper.prototype.mapSettings = function(t, _, settings) {
  SeenspireMapper.prototype.mapSettings.apply(this, arguments);
  t.pattern = this.options.portrait ? 'random_portrait' : 'random_landscape';
  t.patterns[t.pattern].pinnable = settings.pinnable;
  t.patterns[t.pattern].favourite = settings.favourite;
  return this;
};

export default CanvasMapper;
