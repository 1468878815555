import CanvasCollection from '../CanvasCollection';

function CryptoCoinsCollection() {
  CanvasCollection.apply(this, arguments);
  this.name = 'CryptoCoinsCollection';
}

CryptoCoinsCollection.prototype = Object.create(CanvasCollection.prototype);
CryptoCoinsCollection.prototype.constructor = CryptoCoinsCollection;

CryptoCoinsCollection.prototype.checkItem = function(item) {
  if (
    (item['symbol'] || item['ticker']) &&
    item['24hVolume'] &&
    item['change'] &&
    item['name'] &&
    item['iconUrl'] &&
    item['price'] &&
    item['rank'] &&
    item['marketCap'] &&
    item['sparkline']
  ) {
    item['type'] = 'coin';
    return true;
  }
  return false;
};

CryptoCoinsCollection.prototype.addProxy = function(item) {
  if (document.domain === 'localhost') {
    if (item.iconUrl) {
      item.iconUrl = 'http://localhost:3000/proxy?url=' + item.iconUrl;
    }
  }
  return this;
};

export default CryptoCoinsCollection;
