import CanvasMapper from '../CanvasMapper';

const TIME_FRAMES = {
  'history_1_day': 'change_percent_24hr',
  'history_1_week': 'change_percent_7d',
  'history_2_weeks': 'change_percent_14d',
  'history_1_month': 'change_percent_30d',
  'history_1_year': 'change_percent_1y',
}

function stepped(a, steps) {
  var step = a.length / steps
  return new Array(steps).fill(0).map((v, i) => {
    return a[Math.floor((i+1)*step)-1]
  });
}

function CryptoCoinsMapper() {
  CanvasMapper.apply(this, arguments);
  this.name = 'CryptoCoinsMapper';
}

CryptoCoinsMapper.prototype = Object.create(CanvasMapper.prototype);
CryptoCoinsMapper.prototype.constructor = CryptoCoinsMapper;

CryptoCoinsMapper.prototype.mapSettings = function(t, manifest, feed) {
  CanvasMapper.prototype.mapSettings.apply(this, arguments);
  return this
    .mapTimeframe(t, feed)
    .mapImageFromManifest(t, 'up', manifest)
    .mapImageFromManifest(t, 'down', manifest);
};

CryptoCoinsMapper.prototype.mapItem = function(_, item, manifest) {
  var t = CanvasMapper.prototype.mapItem.apply(this, arguments);
  var change;
  if (item) {
    t.id = item['id'];
    t.type = 'coin';
    t.timestamp = item['timestamp'];
    //
    t.name.text = this.limit(String(item['name']).toUpperCase(), 14, '..');
    t.ticker.text = String(item['symbol']).toUpperCase();
    t.volume.text = this.formatValue(item['volume_usd_24hr']);
    t.market.text = item['usd_market_cap'] ? this.formatValue(item['usd_market_cap']) : '-';
    t.rank.text = item['market_cap_rank'] ? item['market_cap_rank'] : '-';
    if (t.timeframe) {
      t.history = item[t.timeframe].sort((a, b) => a.time - b.time).filter(a => !!a).map(h => parseFloat(h['priceUsd']));
    } else {
      t.history = item['history_1_day'].sort((a, b) => a.time - b.time).filter(a => !!a).map(h => parseFloat(h['priceUsd']));
    }
    if (t.change_timeframe) {
      if (item[t.change_timeframe]) {
        change = parseFloat(item[t.change_timeframe], 10);
      } else {
        change = this.getChangeFromHistory(t.history);
      }
    } else {
      if (item['change_percent_24hr']) {
        change = parseFloat(item['change_percent_24hr'], 10);
      } else {
        change = this.getChangeFromHistory(t.history);
      }
    }
    t.change.sign = (change > 0 ? '▲' : (change < 0 ? '▼' : ''));
    t.change.text =  change.toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2});
    t.currency.text = 'USD';

    t.value.text = this.formatValue(item['price']);
    t.value.value = item['price'];

    this.mapLogoImage(t, item, manifest);
    //
  }
  return t;
};

CryptoCoinsMapper.prototype.getChangeFromHistory = function (history) {
  return 100 * ((history[history.length - 1] - history[0]) / history[history.length - 1]);
};

CryptoCoinsMapper.prototype.mapTimeframe = function (t, feed) {
  if (t && t.timeframe && feed && feed.timeframe) {
    if (TIME_FRAMES[feed.timeframe]) {
      t.timeframe = feed.timeframe;
    } else {
      t.timeframe = 'history_1_day';
    }
    t.change_timeframe = TIME_FRAMES[feed.timeframe];
  }
  return this;
}

CryptoCoinsMapper.prototype.limit = function (val, limit, placeholder) {
  if (val && val.length > limit) {
    return val.substr(0, limit) + (placeholder || '');
  }
  return val;
}

CryptoCoinsMapper.prototype.formatChange = function (value) {
  const v = parseFloat(value, 10);

  if (Math.abs(v) >= 1000) {
    return `${(v).toLocaleString('en', {minimumFractionDigits: 0, maximumFractionDigits: 0})}`;
  }
  if (Math.abs(v) >= 100) {
    return `${(v).toLocaleString('en', {minimumFractionDigits: 0, maximumFractionDigits: 1})}`;
  }
  if (Math.abs(v) >= 10) {
    return `${(v).toLocaleString('en', {minimumFractionDigits: 0, maximumFractionDigits: 2})}`;
  }
  if (Math.abs(v) >= 1) {
    return `${(v).toLocaleString('en', {minimumFractionDigits: 0, maximumFractionDigits: 3})}`;
  }
  if (Math.abs(v) >= 0.01) {
    return `${(v).toLocaleString('en', {minimumFractionDigits: 0, maximumFractionDigits: 3})}`;
  }
  if (Math.abs(v) >= 0.001) {
    return `${(v).toLocaleString('en', {minimumFractionDigits: 0, maximumFractionDigits: 3})}`;
  }
  return `${(v).toLocaleString('en', {minimumFractionDigits: 0, maximumFractionDigits: 3})}`;
}

CryptoCoinsMapper.prototype.formatValue = function (value) {
  const v = parseFloat(value, 10);

  if (v > 10000000000) {
    return `${(v/1000000000).toLocaleString('en', {minimumFractionDigits: 0, maximumFractionDigits: 1})}B`;
  }
  if (v > 1000000000) {
    return `${(v/1000000000).toLocaleString('en', {minimumFractionDigits: 0, maximumFractionDigits: 2})}B`;
  }
  if (v > 100000000) {
    return `${(v/1000000).toLocaleString('en', {minimumFractionDigits: 0, maximumFractionDigits: 1})}M`;
  }
  if (v > 1000000) {
    return `${(v/1000000).toLocaleString('en', {minimumFractionDigits: 0, maximumFractionDigits: 2})}M`;
  }
  if (v > 10000) {
    return `${(v).toLocaleString('en', {minimumFractionDigits: 0, maximumFractionDigits: 0})}`;
  }
  if (v > 1000) {
    return `${(v).toLocaleString('en', {minimumFractionDigits: 0, maximumFractionDigits: 0})}`;
  }
  if (v > 100) {
    return `${(v).toLocaleString('en', {minimumFractionDigits: 0, maximumFractionDigits: 0})}`;
  }
  if (v > 0.1) {
    return `${(v).toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})}`;
  }
  if (v > 0.001) {
    return `${(v).toLocaleString('en', {minimumFractionDigits: 4, maximumFractionDigits: 4})}`;
  }
  return `${(v).toLocaleString('en', {minimumFractionDigits: 6, maximumFractionDigits: 6})}`;
}

CryptoCoinsMapper.prototype.mapLogoImage = function(t, item, manifest) {
  if (item['logo'] && item['logo'] !== null) {
    t.logo = { url: item['logo'] };
  } else if (item['symbol']) {
    t.logo = { url: `static/socialcanvas/crypto/coins/${String(item['symbol']).toLowerCase()}.svg` };
  } else {
    t.logo = 'logo';
    this.mapImageFromManifest(t, 'logo', manifest)
  }
  return this;
};

export default CryptoCoinsMapper;
