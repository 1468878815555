import moment from '../moment';
import { Seenspire, SeenspireFontLoader, utils } from 'private/seenspire';

function SeenspireMapper() {
  Seenspire.apply(this, arguments);
  this.name = 'SeenspireMapper';
  this._init();
}

SeenspireMapper.prototype = Object.create(Seenspire.prototype);
SeenspireMapper.prototype.constructor = SeenspireMapper;

SeenspireMapper.prototype._init = function() {
  return this;
};

SeenspireMapper.prototype.mapDisplaySettings = function(t, manifest, settings) {
  if (settings) {
    t.colors.primary = settings['color'];
    t.duration = parseInt(settings['duration'], 10);
    t.random = settings['random'];
    t.audio = settings['audio'];
    t.performance = settings['performance'];
    t.font = settings['font'];
    t.theme = settings['theme'];
  }
  return this;
};

SeenspireMapper.prototype.mapSettings = function(t, manifest, settings) {
  return this.mapDisplaySettings(t, manifest, settings).addFonts(t, manifest);
};

SeenspireMapper.prototype.mapItem = function(template) {
  return utils.clone(template);
};

SeenspireMapper.prototype.mapLanguage = function(t, feed) {
  if (feed) {
    t.language = this.getLocale(feed);
  }
  return this;
};

SeenspireMapper.prototype.mapFieldFromManifest = function(field, images) {
  if (typeof field === 'string' && images[field]) {
    return images[field];
  } else if (typeof field === 'object') {
    for (let i in images) {
      if (images[i] === field) return field;
    }
    for (let i in field) {
      if (typeof field[i] === 'string' && images[field[i]]) {
        field[i] = images[field[i]];
      }
    }
  }
  return field;
};

SeenspireMapper.prototype.mapImageFromManifest = function(t, name, manifest) {
  if (t && name && manifest) {
    t[name] = this.mapFieldFromManifest(t[name], manifest.images);
  }
  return this;
};

SeenspireMapper.prototype.mapFonts = function(t) {
  if (t.font) {
    t.isSystemFont = SeenspireFontLoader.prototype.isSystemFont(t.font);
    t.isDefaultFont = t.font === 'Default';
    if (!t.isSystemFont) t.font = t.font.replace(/ /g, '');
    if (t.fonts) {
      t.fonts.header = this.getFont(t, this.options.template.fonts.header, true);
      t.fonts.message = this.getFont(t, this.options.template.fonts.message);
      t.fonts.secondary = this.getFont(t, this.options.template.fonts.secondary);
    }
  }
  return this;
};

SeenspireMapper.prototype.getFont = function(t, font, bold) {
  return t.isDefaultFont ? font : t.isSystemFont ? t.font : t.font + (bold ? '-Bold' : '-Regular');
};

SeenspireMapper.prototype.mapFont = function(t, name, bold) {
  if (t.fonts[name]) {
    t.fonts[name] = this.getFont(t, this.options.template.fonts[name], bold);
  }
  return this;
};

SeenspireMapper.prototype.mapHeader = function(t, item) {
  if (t.header && item.title) t.header.text = item.title;
  return this;
};

SeenspireMapper.prototype.mapMessage = function(t, item) {
  if (t.message && item.description) t.message.text = item.description;
  return this;
};

SeenspireMapper.prototype.mapImage = function(t, item) {
  if (t.image && item.medias && item.medias[0]) {
    if (item.medias[0].formats && item.medias[0].formats.fullHd) {
      t.image = item.medias[0].formats.fullHd;
    }
  }
  return this;
};

SeenspireMapper.prototype.mapThumbnail = function(t, item) {
  if (t.thumb && item.medias && item.medias[0]) {
    if (item.medias[0].formats && item.medias[0].formats.thumb) {
      t.thumb = item.medias[0].formats.thumb;
    }
  }
  return this;
};

SeenspireMapper.prototype.getLocale = function(feed) {
  let locale = 'en';
  if (feed && feed.language && feed.language.slug) {
    locale = feed.language.slug.toLowerCase();
    if (locale === 'no') {
      locale = 'nb';
    }
  }
  return locale;
};

SeenspireMapper.prototype.mapCustomDate = function(t, field, format, date) {
  moment.locale(t.language);
  if (field) field.text = moment(date).format(format);
  return this;
};

SeenspireMapper.prototype.addImageToManifest = function(name, url, manifest, required) {
  if (manifest && manifest.images && name && url) {
    if (!manifest.images[name]) {
      manifest.images[name] = {};
    }
    manifest.images[name].url = url;
    manifest.images[name].required = manifest.images[name].pixel = required;
  }
  return this;
};

SeenspireMapper.prototype.getDefaultLogo = function(url) {
  return url === 'https://static.seenspire.com/v2/frontend/logo.svg'
    ? 'static/common/seenspire-light.svg'
    : this.proxyUrl(url);
};

SeenspireMapper.prototype.proxyUrl = function(url) {
  if (document.domain !== 'localhost') {
    return url;
  }
  if (url) {
    return 'http://localhost:3000/proxy?url=' + url;
  }
};

SeenspireMapper.prototype.addFonts = function(t, manifest) {
  if (manifest && t) {
    manifest.fonts.introFonts = t.hasIntro ? manifest.introFonts : [];
    manifest.fonts.defaultFonts = !t.font || t.font === 'Default' ? manifest.defaultFonts : [];
  }
  return this;
};

export default SeenspireMapper;
