import { Lightning, Router } from '@lightningjs/sdk'
import qrcode from 'qrcode'

import {
  SeenspireBox,
  SeenspireImageBox,
  SeenspireTextBox,
  SeenspireFontLoader,
} from 'private/seenspire'
import { getCode } from './utils'
import { showLoader } from './loader'
import realtime from './Realtime'
import log from './log'

export default class Qr extends Lightning.Component {
  constructor(stage, props) {
    super(stage, props)
    // console.log('QR', props, document.location.hash);
    if (document.location.hash === '#qr') {
      this.setupQr()
    }
  }

  set params(args) {
    // console.log('QR ARGS', args.reset, args.qr,  document.location.hash)
    if ((args.reset || args.qr) && document.location.hash === '#qr') {
      this.setupQr(args.qr)
    }
  }

  setupQr(qr) {
    const [existing, generated] = getCode()
    // console.log('setupQr', qr, existing, generated)
    if (qr === existing) {
      this.setupEvents(qr)
      this.printCode(qr)
    } else if (existing) {
      showLoader()
      realtime.fetchScreen(existing, (data) => {
        // console.log('FETCHED', data)
        if (data && data.length && data[0] && data[0]['profile_id']) {
          Router.navigate(`crypto/${existing}`, { profileId: data[0]['profile_id'] })
        } else {
          this.setupEvents(existing)
          this.printCode(existing)
        }
      }, true)
    }
    if (generated) {
      this.setupEvents(generated)
      this.printCode(generated)
    }
  }

  showQr(qrImage) {
    this.htmlQr = new SeenspireBox('qr', { classes: 'abs wrap' }).createNode().appendTo(document.body)
    const Bg = new SeenspireBox('Bg', {
      background: '#000000'
    }, this.htmlQr).createNode().appendTo(this.htmlQr)

    const p = window.innerWidth < window.innerHeight
    const framePadding = p ? '6.7vh' : '12vh'

    const Frame = new SeenspireBox('Frame', {
      top: framePadding,
      left: framePadding,
      bottom: framePadding,
      right: framePadding,
      background: '#000000',
      classes: 'abs',
      style: 'border: 1px solid #56667e; border-radius: 10px;'
    }, Bg).createNode().appendTo(Bg)

    const LogoWrap = new SeenspireBox('LogoWrap', {
      width: p ? '50vw' : ['20.4vw', '5.9vw'],
      height: p ? '5.4vh' : '9.8vh',
      top: p ? '-2.7vh' : '-4.9vh',
      left: p ? 'center' : ['9.2vh', '2.95vw'],
      style: 'abs',
      background: '#000000',
    }, Frame).createNode().appendTo(Frame)

    const Logo = new SeenspireImageBox('Logo', {
      width: p ? '48vw' : '90%',
      height: p ? '3.5vh' : '6.3vh',
      left: 'center',
      top: 'center',
      classes: 'abs',
      image: {
        url: './static/common/logo.svg',
        width: 463,
        height: 44,
        size: 'contain',
      },
    }, LogoWrap).createNode().appendTo(LogoWrap)

    // const Logo = new SeenspireTextBox('Logo', {
    //   width: p ? '48vw' : '90%',
    //   height: p ? '3.5vh' : '6.3vh',
    //   left: 'center',
    //   top: 'center',
    //   style: 'white-space: nowrap;',
    //   classes: 'abs',
    //   fit: 'height',
    //   text: {
    //     text: 'CRYPTO CANVAS',
    //     line: 1.2,
    //     lines: 1,
    //     align: 'center',
    //     bold: true,
    //     font: 'Thicccboi-ExtraBold',
    //     color: '#ffffff',
    //     bold: true
    //   },
    // }, LogoWrap)
    // Logo.options.content = '';
    // Logo.options.html = '<span style="font-family:Thicccboi-Medium;font-weight:400;">CRYPTO</span> <span>CANVAS</span>';
    // Logo.createNode().appendTo(LogoWrap)

    const contentPadding = p ? '2.6vh' : '9.2vh'
    const Content = new SeenspireBox('Content', {
      top: contentPadding,
      left: contentPadding,
      bottom: contentPadding,
      right: contentPadding
    }, Frame).createNode().appendTo(Frame)

    const qrRadius = Math.round(0.015 * (p ? window.innerHeight : window.innerWidth));
    const QrWrap = new SeenspireBox('QrWrap', {
      width: p ? '26vh' : '20.4vw',
      left: p ? 'center': '5.9vw',
      height: p ? '26vh' : '20.4vw',
      top: p ? '5.2vh' : 'center',
      style: 'border-radius: ' + qrRadius + 'px;',
      background: '#FFFFFF'
    }, Content).createNode().appendTo(Content)

    const qrPadding = p ? '0.52vh' : '0.52vw';
    const QrImage = new SeenspireImageBox('QrImage', {
      top: qrPadding,
      left: qrPadding,
      right: qrPadding,
      bottom: qrPadding,
      image: {
        url: qrImage,
        width: 100,
        height: 100,
        size: 'cover'
      }
    }, QrWrap).createNode().appendTo(QrWrap)

    const Hr = new SeenspireBox('Hr', {
      height: p ? 1 : '20.4vw',
      top: p ? [QrWrap, '5.2vh'] : 'center',
      width: p ? '26vh' : 1,
      left: p ? 'center' : [QrWrap, '5.9vw'],
      background: '#56667e'
    }, Content).createNode().appendTo(Content)

    const Title = new SeenspireTextBox('Title', {
      top: p ? [Hr, '5.2vh'] : '12vh',
      left: p ? 0 : [Hr, '5.9vw'],
      right: p ? 0 : '3.7vw',
      height: p ? '7.8vh' : '11.1vh',
      classes: 'abs',
      text: {
        text: 'Pair and control CryptoCanvas with Telegram',
        line: 1.2,
        font: 'Indivisible-Bold',
        color: '#ffffff',
        bold: true
      }
    }, Content).createNode().appendTo(Content)

    const size = Math.round(window.innerHeight * (p ? 0.027 : 0.048));
    const PointOne = new SeenspireBox('PointOne', {
      left: p ? 0 : [Hr, '5.9vw'],
      top: [Title, p ? '4vh' : '5.5vh'],
      width: size,
      height: size,
      background: '#FFFFFF',
      style: 'border-radius: 50%;'
    }, Content).createNode().appendTo(Content)

    const PointOneLabel = new SeenspireTextBox('PointOneLabel', {
      width: size,
      height: size,
      top: 'center',
      left: 'center',
      fit: 'height',
      text: {
        text: '1',
        size: 0.57 * size,
        line: 1,
        align: 'center',
        font: 'Indivisible-Regular',
        color: '#000000'
      }
    }, PointOne).createNode().appendTo(PointOne)

    const PointOneTextWrap = new SeenspireBox('PointOneTextWrap', {
      right: '5vw',
      height: p ? '6vh' : '5.8vh',
      top: [PointOne, p ? '-3vh' : '-2.9vh', -size/2],
      left: [PointOne, 20]
    }, Content).createNode().appendTo(Content)
    const PointOneText = new SeenspireTextBox('PointOneText', {
      height: '100%',
      width: '100%',
      fit: 'height',
      top: 'center',
      text: {
        text: 'In Telegram, search for\nand open “CryptoCanvasTV_bot”.',
        line: 1.2,
        lines: p ? 3 : 2,
        maximum: 0.7 * size,
        font: 'Indivisible-Regular',
        color: '#ffffff'
      }
    }, PointOneTextWrap).createNode().appendTo(PointOneTextWrap)

    const PointTwo = new SeenspireBox('PointTwo', {
      left: p ? 0 : [Hr, '5.9vw'],
      top: [PointOne, p ? '4vh' : '5.5vh'],
      width: size,
      height: size,
      background: '#FFFFFF',
      style: 'border-radius: 50%;'
    }, Content).createNode().appendTo(Content)

    const PointTwoLabel = new SeenspireTextBox('PointTwoLabel', {
      width: size,
      height: size,
      top: 'center',
      left: 'center',
      fit: 'height',
      text: {
        text: '2',
        size: 0.57 * size,
        line: 1,
        align: 'center',
        font: 'Indivisible-Regular',
        color: '#000000'
      }
    }, PointTwo).createNode().appendTo(PointTwo)

    const PointTwoTextWrap = new SeenspireBox('PointTwoTextWrap', {
      right: '5vw',
      height: p ? '6vh' : '5.8vh',
      top: [PointTwo, p ? '-3vh' : '-2.9vh', -size/2],
      left: [PointTwo, 20],
    }, Content).createNode().appendTo(Content)
    const PointTwoText = new SeenspireTextBox('PointTwoText', {
      height: '100%',
      width: '100%',
      fit: 'height',
      top: 'center',
      text: {
        text: 'Scan the QR code to pair your TV.',
        line: 1.2,
        lines: p ? 3 : 2,
        maximum: PointOneText.textInfo.fontSize,
        font: 'Indivisible-Regular',
        color: '#ffffff'
      }
    }, PointTwoTextWrap).createNode().appendTo(PointTwoTextWrap)

    // const Info = new SeenspireTextBox('Info', {
    //   bottom: 0,
    //   left: 0,
    //   height: 'auto',
    //   width: 'auto',
    //   background: '#000000',
    //   style: 'padding: 5px; opacity: 0.5;',
    //   text: {
    //     size: 16,
    //     family: 'monosnap',
    //     color: '#FFFFFF',
    //     text: '1.0.5'
    //     //text: `${window.innerWidth}x${window.innerHeight}: ${navigator.userAgent}`
    //   }
    // }, Bg).createNode().appendTo(Bg)
    new SeenspireBox('version-tag', {
      bottom: 10,
      height: 'auto',
      width: '100%',
      content: '1.0.6',
      style: 'font-size: 20px; color: white; text-align: center; opacity: 0.5;'
    }, Bg).createNode().appendTo(Bg)
  }

  async printCode(code) {
    log('Qr.printCode', code)
    if (code) {
      const qrImage = await qrcode.toDataURL(code)
      const fontLoader = new SeenspireFontLoader()
      fontLoader.preloadMultiple([
        { name: 'Thicccboi-ExtraBold', path: '../static/fonts/' },
        { name: 'Thicccboi-Medium', path: '../static/fonts/' },
        { name: 'Indivisible-Bold', path: '../static/fonts/' },
        { name: 'Indivisible-Regular', path: '../static/fonts/' },
        { name: 'Indivisible-SemiBold', path: '../static/fonts/' },
      ], () => this.showQr(qrImage))
    }
  }

  hide() {
    if (this.htmlQr) {
      this.htmlQr.remove();
      delete this.htmlQr;
    }
  }

  setupEvents(code) {
    if (code && realtime) {
      realtime.subscribeScreenAdd(code, (payload) => {
        // console.log('!payload', payload)
        const profileId = payload && payload.new && payload.new.profile_id
        if (profileId) {
          log('Qr.onScreenAdd', profileId)
          this.hide()
          Router.navigate(`crypto/${code}`, { profileId })
        }
      })
    }
  }

  static _template() {
    return {
      w: window.innerWidth,
      h: window.innerHeight,
      color: '0x00000000',
      rect: true,
      clipping: true,
    };
  }
}
