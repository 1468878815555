import { Lightning } from '@lightningjs/sdk';

function findComponent(tree, key) {
  if (typeof tree != 'object') {
    return;
  }
  if (tree[key]) {
    return tree[key];
  } else if (typeof tree == 'object') {
    for (let i in tree) {
      const found = findComponent(tree[i], key);
      if (found) return found;
    }
  }
}

function getTag(component) {
  if (component.parentComponent) {
    return getTag(component.parentComponent) + '.' + component.id;
  } else {
    return component.id;
  }
}

function parseSvgTexture(t) {
  if (typeof t == 'object') {
    if (t.svgTexture) {
      const { src, w, h } = t.svgTexture;
      t.texture = Lightning.Tools.getCanvasTexture((cb, stage) => {
        Lightning.Tools.createSvg(cb, stage, src, w, h);
      });
      delete t.svgTexture;
    }
    for (let i in t) {
      parseSvgTexture(t[i]);
    }
  }
}

function getTemplate(component) {
  if (component && typeof component._template == 'function') {
    const t = component._template();
    parseSvgTexture(t);
    return t;
  }
  return {};
}

export default class Seenspire extends Lightning.Component {
  constructor(stage, properties) {
    super(stage, properties);
    this.config = Object.assign({}, properties.config);
    this.options = Object.assign({}, properties.options);
  }

  updateConfig(config) {
    this.config = Object.assign({}, config);
    this.childList.clear();
    this._construct();
    this._build();
  }

  checkContent() {
    return this;
  }

  getPercents(number) {
    return Math.round(10000 / number) / 100 + '%';
  }

  _construct() {
    this.checkContent();
    this.c = {};
  }

  _build() {
    const patch = {};
    if (this.c) {
      for (let i in this.c) {
        if (this.c[i].parentComponent) {
          const parentId = this.c[i].parentComponent.id;
          const parent = findComponent(patch, parentId);
          if (parent) {
            if (parent.Wrap) {
              if (parent.Wrap.SubNode) {
                parent.Wrap.SubNode[i] = getTemplate(this.c[i]);
              } else {
                parent.Wrap[i] = getTemplate(this.c[i]);
              }
            } else {
              parent[i] = getTemplate(this.c[i]);
            }
          } else {
            console.warn('Parent not found for', i, 'parent', parentId);
          }
        } else {
          patch[i] = getTemplate(this.c[i]);
        }
      }
    }
    this.patch(patch, true);
  }

  playIn(start) {
    if (this.c) {
      for (let i in this.c) {
        const tag = getTag(this.c[i]);
        if (typeof this.c[i]._lngIn == 'function') {
          this.c[i]._lngIn(this.tag(tag), start);
        } else if (typeof this.tag(tag).playIn == 'function') {
          this.tag(tag).playIn(start);
        }
      }
    }
  }

  playOut(start) {
    if (this.c) {
      for (let i in this.c) {
        const tag = getTag(this.c[i]);
        if (typeof this.c[i]._lngIn == 'function') {
          this.c[i]._lngOut(this.tag(tag), start);
        } else if (typeof this.tag(tag).playIn == 'function') {
          this.tag(tag).playOut(start);
        }
      }
    }
  }

  onOutStart(callback) {
    this.outStart = callback;
  }

  onOutEnd(callback) {
    this.outEnd = callback;
  }

  onReady(callback) {
    if (callback) {
      if (this.isReady) {
        callback();
      } else {
        this.readyCallback = callback;
      }
    } else {
      if (this.readyCallback) {
        this.readyCallback();
        delete this.readyCallback;
      }
      this.isReady = true;
    }
    return this;
  }

  static _template() {
    return {};
  }
}
