import CanvasMapper from '../CanvasMapper';
import moment from '../../../moment';

function CryptoVideosMapper() {
  CanvasMapper.apply(this, arguments);
  this.name = 'CryptoVideosMapper';
}

CryptoVideosMapper.prototype = Object.create(CanvasMapper.prototype);
CryptoVideosMapper.prototype.constructor = CryptoVideosMapper;

CryptoVideosMapper.prototype.mapItem = function(_, item) {
  var t = CanvasMapper.prototype.mapItem.apply(this, arguments);
  if (item) {
    moment.locale('en');
    t.id = item['id'];
    t.type = 'video';
    t.date = moment(item['date']).format('HH:mm - MMM D');
    t.video = {
      url: item['url'],
      width: item['width'],
      height: item['height'],
      duration: item['duration']
    }
  }
  return t;
};

export default CryptoVideosMapper;
