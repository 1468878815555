import { Lightning, Router } from '@lightningjs/sdk'
import Presentation from './Presentation'
import realtime from './Realtime'
import { setCode } from './utils'
import log from './log'

export default class SeenspireApp extends Lightning.Component {
  constructor(stage, props) {
    super(stage, props)
    // console.log('APP', props, document.location.hash)
    // console.log('DIM', window.innerWidth, window.innerHeight)
  }
  set params(args) {
    log('SeenspireApp.params')
    // console.log('APP ARGS', args, document.location.hash)
    if (args.screenId) {
      setCode(args.screenId)
      this.setScreen(args.screenId, args.profileId)
      this.setupEvents(args.screenId)
    }
  }

  setupEvents(code) {
    log('SeenspireApp.setupEvents')
    if (code && realtime) {
      realtime.subscribeScreenRemove(code, (payload) => {
        log('SeenspireApp.onScreenRemove', payload)
        console.log('SeenspireApp.onScreenRemove', payload)
        if (this.app) {
          this.app.destroy()
          Router.navigate('qr', { reset: true })
        }
      })
      realtime.subscribeSettings(code, (payload) => {
        log('SeenspireApp.onSettings', payload)
        console.log('SeenspireApp.onSettings', payload)
        if (this.app) {
          this.app.updateSettings(payload)
        }
      })
    }
  }

  getProxyUrl() {
    if (
      window['settings'] &&
      window['settings'].seenspireSettings &&
      window['settings'].seenspireSettings.PROXY_URL
    ) {
      return `${window['settings'].seenspireSettings.PROXY_URL}?url=`
    }
    return ''
  }

  getTemplate() {
    if (
      window['settings'] &&
      window['settings'].seenspireSettings &&
      window['settings'].seenspireSettings.TEMPLATE
    ) {
      return window['settings'].seenspireSettings.TEMPLATE;
    }
    return 'crypto';
  }

  setScreen(screenId, profileId) {
    log('SeenspireApp.setScreen');
    window.presentation = this.app = new Presentation(this.stage, {
      w: window.innerWidth,
      h: window.innerHeight,
      color: '0x00000000',
      rect: true,
      clipping: true,
      type: Presentation,
      config: {
        screenId,
        profileId,
        type: this.getTemplate(),
        autoplay: true,
      },
    })
    this.childList.add(this.app)
  }

  static _template() {
    const portrait = window.innerWidth < window.innerHeight
    return {
      w: window.innerWidth,
      h: window.innerHeight,
      color: '0x00000000',
      rect: true,
      clipping: true,
      /* v1
      BgImage: {
        x: portrait ? -1 : -2,
        y: portrait ? -2 : -1,
        w: window.innerWidth + (portrait ? 2 : 4),
        h: window.innerHeight + (portrait ? 4 : 2),
        src: portrait ? 'static/socialcanvas/crypto/splash-portrait.jpg' : 'static/socialcanvas/crypto/splash.jpg'
      }*/
    }
  }
}
