import CanvasMapper from '../CanvasMapper';

function CryptoCoinsMapper() {
  CanvasMapper.apply(this, arguments);
  this.name = 'CryptoCoinsMapper';
}

CryptoCoinsMapper.prototype = Object.create(CanvasMapper.prototype);
CryptoCoinsMapper.prototype.constructor = CryptoCoinsMapper;

CryptoCoinsMapper.prototype.mapSettings = function(t, manifest, feed) {
  CanvasMapper.prototype.mapSettings.apply(this, arguments);
  return this
    .mapImageFromManifest(t, 'up', manifest)
    .mapImageFromManifest(t, 'down', manifest);
};

CryptoCoinsMapper.prototype.mapItem = function(_, item, manifest) {
  var t = CanvasMapper.prototype.mapItem.apply(this, arguments);
  if (item) {
    t.id = item['id'];
    t.type = 'coin';
    t.timestamp = item['listedAt'];
    //
    t.name.text = String(item['name']).toUpperCase();
    t.ticker.text = String(item['symbol'] || item['ticker']).toUpperCase();
    t.volume.text = this.formatValue(item['24hVolume'], 10);
    t.market.text = this.formatValue(item['marketCap'], 10);
    t.change.text = parseFloat(item['change'], 10).toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2});
    t.rank.text = String(item['rank']);
    t.supply.text = this.formatValue(100000 + Math.round(100000 * Math.random()));
    t.currency.text = 'USD';
    t.history = item['sparkline'].map(h => parseFloat(h, 10));

    t.value.text = this.formatValue(item['price']);

    this.mapLogoImage(t, item, manifest);
    //
  }
  return t;
};

CryptoCoinsMapper.prototype.formatValue = function (value) {
  const v = parseFloat(value, 10);

  if (v > 1000000000) {
    return `${(v/1000000000).toLocaleString('en', {minimumFractionDigits: 0, maximumFractionDigits: 3})}B`;
  }
  if (v > 1000000) {
    return `${(v/1000000).toLocaleString('en', {minimumFractionDigits: 0, maximumFractionDigits: 3})}M`;
  }
  if (v > 1000) {
    return `${(v).toLocaleString('en', {minimumFractionDigits: 0, maximumFractionDigits: 2})}`;
  }
  if (v > 10) {
    return `${(v).toLocaleString('en', {minimumFractionDigits: 0, maximumFractionDigits: 4})}`;
  }
  if (v > 0) {
    return `${(v).toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 4})}`;
  }
  if (v > 0.01) {
    return `${(v).toLocaleString('en', {minimumFractionDigits: 4, maximumFractionDigits: 6})}`;
  }
  if (v > 0.0001) {
    return `${(v).toLocaleString('en', {minimumFractionDigits: 6, maximumFractionDigits: 8})}`;
  }
  return `${(v).toLocaleString('en', {minimumFractionDigits: 8, maximumFractionDigits: 8})}`;
}

CryptoCoinsMapper.prototype.mapLogoImage = function(t, item, manifest) {
  if (item.logo) {
    t.logo = item.logo;
  } else {
    t.logo = 'logo';
    this.mapImageFromManifest(t, 'logo', manifest)
  }
  return this;
};

export default CryptoCoinsMapper;
