import CanvasCollection from '../CanvasCollection';

function CanvasSponsoredCollection() {
  CanvasCollection.apply(this, arguments);
  this.name = 'CanvasSponsoredCollection';
}

CanvasSponsoredCollection.prototype = Object.create(CanvasCollection.prototype);
CanvasSponsoredCollection.prototype.constructor = CanvasSponsoredCollection;

CanvasSponsoredCollection.prototype.checkItem = function(item) {
  if (
    item['imageUrl'] &&
    item['width'] &&
    item['height'] &&
    item['date']
  ) {
    item['type'] = 'sponsored';
    return true;
  }
  return false;
};

CanvasSponsoredCollection.prototype.checkCategory = function (item, categories) {
  if (!categories) return true;
  const ar = item.width / item.height;
  const condition =
    categories.indexOf('landscape') > -1 && ar > 1.3 ||
    categories.indexOf('portrait') > -1 && ar < 0.7 ||
    categories.indexOf('square') > -1 && ar < 1.3 && ar > 0.7;
  return condition;
}

CanvasSponsoredCollection.prototype.getNext = function(categories) {
  if (typeof this.count === 'undefined') {
    this.count = 0;
  } else {
    this.count = this.count + 1;
  }

  let min;
  if (this.items) {
    for (let i = 0; i < this.items.length; i++) {
      if (this.checkCategory(this.items[i], categories) && (!min || this.items[i].order < min.order)) {
        min = this.items[i];
      }
    }
    if (!min && this.items.length === 1) {
      min = this.items[0];
    }
  } else {
    console.warn('collection items undefined');
    return null;
  }

  if (min) {
    min.order = ++this.order;
    return min;
  } else {
    console.warn('empty collection', this);
    return null;
  }
};

export default CanvasSponsoredCollection;
