import CanvasMapper from '../CanvasMapper';

function CryptoDominanceMapper() {
  CanvasMapper.apply(this, arguments);
  this.name = 'CryptoDominanceMapper';
}

CryptoDominanceMapper.prototype = Object.create(CanvasMapper.prototype);
CryptoDominanceMapper.prototype.constructor = CryptoDominanceMapper;

// CryptoDominanceMapper.prototype.mapSettings = function(t, manifest, feed) {
//   CanvasMapper.prototype.mapSettings.apply(this, arguments);
//   return this
//     .mapImageFromManifest(t, 'up', manifest)
//     .mapImageFromManifest(t, 'down', manifest);
// };

CryptoDominanceMapper.prototype.mapItem = function(_, item, manifest) {
  var t = CanvasMapper.prototype.mapItem.apply(this, arguments);
  var change;
  if (item) {
    t.id = item['id'];
    t.type = 'dominance';
    t.timestamp = item['timestamp'];
    t.name.text = String(item['name']).toUpperCase();
    t.ticker.text = String(item['symbol']).toUpperCase();
    t.volume.text = this.formatValue(item['bitcoin_volume']);
    t.market.text = this.formatValue(item['bitcoin_market_cap']);
    change = parseFloat(item['bitcoin_market_cap_change'], 10);
    t.change.value = change;
    t.change.sign = (change > 0 ? '▲' : (change < 0 ? '▼' : ''));
    t.change.text = change.toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2});
    t.value.text = Math.round(item['bitcoin_dominance']).toString();
    t.value.value = item['bitcoin_dominance'];

    // this.mapLogoImage(t, item, manifest);
  }
  return t;
};

// CryptoDominanceMapper.prototype.limit = function (val, limit, placeholder) {
//   if (val && val.length > limit) {
//     return val.substr(0, limit) + (placeholder || '');
//   }
//   return val;
// }

CryptoDominanceMapper.prototype.formatChange = function (value) {
  const v = parseFloat(value, 10);

  if (Math.abs(v) >= 1000) {
    return `${(v).toLocaleString('en', {minimumFractionDigits: 0, maximumFractionDigits: 0})}`;
  }
  if (Math.abs(v) >= 100) {
    return `${(v).toLocaleString('en', {minimumFractionDigits: 0, maximumFractionDigits: 1})}`;
  }
  if (Math.abs(v) >= 10) {
    return `${(v).toLocaleString('en', {minimumFractionDigits: 0, maximumFractionDigits: 2})}`;
  }
  if (Math.abs(v) >= 1) {
    return `${(v).toLocaleString('en', {minimumFractionDigits: 0, maximumFractionDigits: 3})}`;
  }
  if (Math.abs(v) >= 0.01) {
    return `${(v).toLocaleString('en', {minimumFractionDigits: 0, maximumFractionDigits: 3})}`;
  }
  if (Math.abs(v) >= 0.001) {
    return `${(v).toLocaleString('en', {minimumFractionDigits: 0, maximumFractionDigits: 3})}`;
  }
  return `${(v).toLocaleString('en', {minimumFractionDigits: 0, maximumFractionDigits: 3})}`;
}

CryptoDominanceMapper.prototype.formatValue = function (value) {
  const v = parseFloat(value, 10);

  if (v > 10000000000000) {
    return `${(v/1000000000000).toLocaleString('en', {minimumFractionDigits: 0, maximumFractionDigits: 0})}T`;
  }
  if (v > 1000000000000) {
    return `${(v/1000000000000).toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})}T`;
  }
  if (v > 10000000000) {
    return `${(v/1000000000).toLocaleString('en', {minimumFractionDigits: 0, maximumFractionDigits: 1})}B`;
  }
  if (v > 1000000000) {
    return `${(v/1000000000).toLocaleString('en', {minimumFractionDigits: 0, maximumFractionDigits: 2})}B`;
  }
  if (v > 100000000) {
    return `${(v/1000000).toLocaleString('en', {minimumFractionDigits: 0, maximumFractionDigits: 1})}M`;
  }
  if (v > 1000000) {
    return `${(v/1000000).toLocaleString('en', {minimumFractionDigits: 0, maximumFractionDigits: 2})}M`;
  }
  if (v > 10000) {
    return `${(v).toLocaleString('en', {minimumFractionDigits: 0, maximumFractionDigits: 0})}`;
  }
  if (v > 1000) {
    return `${(v).toLocaleString('en', {minimumFractionDigits: 0, maximumFractionDigits: 0})}`;
  }
  if (v > 100) {
    return `${(v).toLocaleString('en', {minimumFractionDigits: 0, maximumFractionDigits: 0})}`;
  }
  if (v > 0.1) {
    return `${(v).toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})}`;
  }
  if (v > 0.001) {
    return `${(v).toLocaleString('en', {minimumFractionDigits: 4, maximumFractionDigits: 4})}`;
  }
  return `${(v).toLocaleString('en', {minimumFractionDigits: 6, maximumFractionDigits: 6})}`;
}

// CryptoDominanceMapper.prototype.mapLogoImage = function(t, item, manifest) {
//   if (item['logo'] && item['logo'] !== null) {
//     t.logo = { url: item['logo'] };
//   } else if (item['symbol']) {
//     t.logo = { url: `static/socialcanvas/crypto/coins/${String(item['symbol']).toLowerCase()}.svg` };
//   } else {
//     t.logo = 'logo';
//     this.mapImageFromManifest(t, 'logo', manifest)
//   }
//   return this;
// };

export default CryptoDominanceMapper;
