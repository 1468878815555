import { v4 as uuidv4 } from 'uuid'

export function getCode() {
  let existing, generated
  if (window.localStorage) {
    existing = window.localStorage.getItem('uuid')
  }
  if (!existing) {
    generated = uuidv4() //'9a05b15e-1fb3-4a02-a5ba-e84af035f602'
    if (window.localStorage) {
      window.localStorage.setItem('uuid', generated)
    }
  }
  return [existing, generated]
}

export function setCode(code) {
  if (window.localStorage) {
    window.localStorage.setItem('uuid', code)
  }
}
